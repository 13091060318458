import React, { useEffect, useContext } from 'react'
import Login from '../components/Login'
import { UserContext, isLoggedIn } from '@parallelpublicworks/entitysync'
import { navigate } from 'gatsby'

function LoginPage({ location }) {
  const userContext = useContext(UserContext)

  useEffect(() => {
    if (isLoggedIn(userContext)) {
      const redirectPath = location.state ? location.state.loginRedirect : false
      navigate(redirectPath ? redirectPath : '/')
    }
  }, [isLoggedIn(userContext)])

  return <Login location={location} />
}
export default LoginPage
